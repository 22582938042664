<template>
   <div class="px-5 app-container v-sheet v-sheet--shaped mt-4">
      <v-btn color="primary" class="ma-4 mb-8 ml-0 pl-0" width="200" x-large @click="createItem">
         <span class="text-caption text-md-left font-weight-medium pl-3">
            Add a Company
         </span>
         <v-icon class="pl-16" right>
            mdi-plus-circle-outline
         </v-icon>
      </v-btn>
      <v-card width="1040px">
         <v-data-table
            ref="newTable"
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :header-props="{ sortIcon: 'mdi-chevron-down' }"
            class="elevation-1 v-sheet v-sheet--shaped">
             <template v-slot:item.actions="{ item }">
               <v-btn plain text x-small color="secondary" class="ma-0 pa-1" @click="editItem(item)">
                  <v-icon x-small>
                     mdi-pencil-outline
                  </v-icon>
                  Edit
               </v-btn>
               <v-btn plain text x-small color="secondary" class="ma-0 pa-1" @click="deleteItem(item)">
                  <v-icon x-small>
                     mdi-close-circle-outline
                  </v-icon>
                  Remove
               </v-btn>
            </template>
            <template v-slot:item.logo="{ item }" >
               <div class="thumb_left">
                  <img style="height:45px" class="mt-2 pa-1" :src="item.logo"/>
               </div>
            </template>

         </v-data-table>
      </v-card>
      <!-- <CompanyForm :item="item" @clear_item="clearItem"/> -->
      <v-slide-x-reverse-transition>
         <FormCompany v-if="item" :item.sync="item" @updated="reset" />
      </v-slide-x-reverse-transition>

   </div>
</template>

<style scoped>
.thumb_container{ width: 100%; height: 100%; text-align: center; }
.thumb_left{ width: 100%; height: 100%; text-align: left; }
</style>

<script>
import Company from '@/models/Company.js';
import CompanyService from '@/service/CompanyService.js';
import FormCompany from '@/components/Forms/FormCompany.vue';
import util from '@/utils';

export default {
   components: {
      FormCompany,
   },
   data() {
      return {
         headers: [
            { text: 'Id', value: 'id' },
            { text: 'Name', align: 'start', sortable: false, value: 'name' },
            { text: 'Address', value: 'address' },
            { text: 'Logo', value: 'logo' },
            { text: 'Users Count', value: 'usersCount' },
            { text: 'Exp Date', value: 'expDate' },
            { text: 'Actions', value: 'actions', sortable: false },
         ],
         loading: false,
         sort: 'id',
         order: 'desc',
         total: 0,
         page: 0,
         search: {},
         items: [],
         item: null,
      };
   },
   mounted() {
      this.callServiceList();
   },
   methods: {
      reset() {
         this.item = null;
         this.callServiceList();
      },
      createItem() {
         this.item = new Company();
      },
      editItem(item) {
         this.item = new Company(item);
      },
      callServiceList() {
         util.loading(1);
         const companyService = new CompanyService(this);
         companyService.list().then((list) => {
            if (list) {
               this.items = list.data;
               this.total = list.data.length;
            }
            util.loading(false);
         });
      },
   },
};
</script>
