import ParentModel from '@/models/parent/ParentModel.js';

export default class Company extends ParentModel {
   constructor(data) {
      super(data);
      if (data != null) {
         this.id = data.id;
         this.name = data.name;
         this.address = data.address;
         this.logo = data.logo;

         this.usersCount = data.usersCount;
         this.expDate = data.expDate;
         this.email = data.email;
      } else {
         this.id = '';
         this.name = '';
         this.address = '';
         this.logo = '';
         this.usersCount = '';
         this.expDate = '';
         this.email = '';
      }
   }

   get toDto() {
      const dto = {};
      dto.id = this.id;
      dto.name = this.name;
      dto.address = this.address;
      dto.logo = this.logo;
      dto.usersCount = this.usersCount;
      dto.expDate = this.expDate;
      dto.email = this.email;
      return dto;
   }
}
