<template>
   <div class="sideForm">
      <v-card>
         <v-card-title>
            {{ (!selfItem.id) ? 'Add a new company' : 'Edit company' }}
         </v-card-title>
         <v-card>
            <v-icon class="closeBTN" @click="closeForm"> mdi-close </v-icon>
         </v-card>
      </v-card>
      <v-divider></v-divider>
      <v-form ref="form" v-model="valid" lazy-validation>
         <v-text-field
            v-model="selfItem.name"
            :rules="nameRules"
            label="Company Name"
            persistent-placeholder
            required></v-text-field>
         <v-text-field
            v-model="selfItem.address"
            :rules="nameRules"
            label="Company Address"
            persistent-placeholder
            required></v-text-field>
         <v-file-input accept="image/*"
                       label="Select Company logo"
                       prepend-icon=""
                       append-icon="mdi-dots-horizontal"
                       v-model="tmplogo"
                       persistent-placeholder
                       @change="addFiles">
         </v-file-input>
         <v-col v-if="tmplogo || fileDat" sm="4">
            <v-img
               :src="fileDat" ref="file"
            ></v-img>
         </v-col>

         <v-text-field
            v-model="selfItem.usersCount"
            :rules="usersCountRules"
            label="Maximum users for company"
            persistent-placeholder
            required
         ></v-text-field>

         <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="selfItem.expDate"
            transition="scale-transition"
            offset-y
            persistent-placeholder
            max-width="290px"
            min-width="auto"
         >
            <template v-slot:activator="{ on, attrs }">
               <v-text-field
                  v-model="selfItem.expDate"
                  label="Company exp date"
                  append-icon="mdi-calendar"
                  readonly
                  persistent-placeholder
                  v-bind="attrs"
                  v-on="on"
               ></v-text-field>
            </template>
            <v-date-picker v-model="selfItem.expDate" no-title scrollable>
               <v-spacer></v-spacer>
               <v-btn text color="primary" @click="menu = false">
                  Cancel
               </v-btn>
               <v-btn text color="primary" @click="$refs.menu.save(selfItem.expDate)">
                  OK
               </v-btn>
            </v-date-picker>
         </v-menu>

         <v-text-field
            v-if="!selfItem.id"
            v-model="selfItem.email"
            :rules="emailRules"
            label="E-mail"
            persistent-placeholder
            required
         ></v-text-field>
         <br />
         <v-card class="formFooterButtons">
            <v-btn :disabled="!valid" color="info" @click="submit">Apply
               <v-icon
                  right
               >
                  mdi-check-bold
               </v-icon>
            </v-btn>
         </v-card>
      </v-form>
   </div>
</template>

<script>
import CompanyService from '@/service/CompanyService.js';
import Company from '@/models/Company.js';
import Util from '@/utils';

export default {
   name: 'FormCompany',
   props: ['item'],
   watch: {
      item: function (val) {
         if (val != null) {
            this.selfItem = { ...val };
            this.fileDat = { ...val.logo };
         }

         // let imgRef = this.$refs.file
         // imgRef.src = val.logo
      },
   },
   data() {
      return {
         util: Util,
         fileDat: null,
         tmplogo: null,
         readers: null,
         selfItem: this.item,
         valid: false,
         logo: '',
         nameRules: [
            (v) => !!v || 'This field is required',
            (v) => v.length <= 100 || 'Name must be less than 10 characters',
         ],
         emailRules: [
            (v) => !!v || 'This field is required',
            (v) => /.+@.+/.test(v) || 'E-mail must be valid',
         ],
         usersCountRules: [
            (v) => !!v || 'This field is required',
            (v) => v >= 1 || 'This field must ne a number',
         ],
         date: new Date().toISOString().substr(0, 7),
         menu: false,
         modal: false,
         scrolled: false,
         service: new CompanyService(),
      };
   },
   mounted: function () {
      window.addEventListener('scroll', this.scrollListener);
   },
   methods: {
      addFiles() {
         this.readers = new FileReader();
         this.readers.onloadend = (e) => {
            const fileData = this.readers.result;
            const imgRef = this.$refs.file;
            // imgRef.src = fileData
            this.fileDat = fileData;

            // console.log(fileData);
            this.selfItem.logo = fileData;
            // send to server here...
         };

         this.readers.readAsDataURL(this.tmplogo);
      // })
      },
      submit() {
         if (this.$refs.form.validate()) {
            if (!this.selfItem.hasId) {
               const company = new Company(this.selfItem);
               this.service.add(company).then((res) => {
                   if (res.ok) {
                     //this.$mixpanel.track('Company update', {res});
                     Util.sendEvent('Company', 'update', 'success', res);
                     Util.showMessage('added sucessfuly!');
                  } else {
                     //this.$mixpanel.track('Company update error', { res});
                     Util.sendEvent('Company', 'update', 'error', res);
                     Util.showMessage(`error adding: ${res.data.errors}`, 'error');
                  }
               });
            } else {
               this.service.update(this.selfItem.id, this.selfItem).then((res) => {
                 if (res.ok) {
                     //this.$mixpanel.track('Company added', {res});
                     Util.sendEvent('Company', 'add', 'success', res);
                     Util.showMessage('added sucessfuly!');
                  } else {
                     //this.$mixpanel.track('Company add error', { res});
                     Util.sendEvent('Company', 'add', 'error', res);
                     Util.showMessage(`error adding: ${res.data.errors}`, 'error');
                  } 
               });
            }
            this.$emit('updated', null);
         }
      },
      clear() {
         this.$refs.form.reset();
      },
      closeForm() {
         this.$emit('update:item', null);
         this.selfItem = null;
      },
      scrollListener: function () {
         if (window.scrollY > 65) { this.scrolled = true; } else { this.scrolled = false; }
      },
   },
   beforeDestroy: function () {
      window.removeEventListener('scroll', this.scrollListener);
   },
};
</script>

<style scoped>
.img-fluid{
  width: 100%;
}
</style>
