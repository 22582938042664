import ParentService from '@/service/ParentService.js';

export default class CompanyService extends ParentService {
   list() {
      const url = `${super.baseUrl}companies`;
      return super.customGet(url);
   }

   export() {
      const url = `${super.baseUrl}companies/export`;
      return super.customGetDownload(url);
   }

   add(dto) {
      const url = `${super.baseUrl}companies`;
      return super.customPost(url, dto);
   }

   update(id, dto) {
      const url = `${super.baseUrl}companies/${id}`;
      return super.customPut(url, dto);
   }
}
